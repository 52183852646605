<template>
  <div>
    <div v-if="pageReady" class="d-flex justify-content-center">
      <div class="container mx-5 px-5 h-100">
        <div id="tpd-lawyer-only-navbar" class="row d-flex mx-auto">
          <ul
            id="tpd-pills-tab"
            class="nav nav-pills d-flex tpd-tabs justify-content-between flex-wrap p-0 overflow-hidden"
          >
            <li
              v-for="item in renderTabs"
              :key="item.title"
              class="nav-item mb-3"
            >
              <router-link
                :to="{
                  name: `${item.title}`,
                  params: { id: $route.params.id },
                }"
              >
                <button
                  :disabled="isDisable(item)"
                  type="button"
                  class="btn nav-link tpd-lawyer-nav bold text-capitalize"
                >
                  {{ item.display_title }}
                </button>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="container-fluid mt-5 overflow-auto p-5 bg-white shadow">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <div
      v-else
      class="min-vh-100 d-flex justify-content-center align-items-center"
    >
      <div>
        <div
          class="spinner-border text-primary"
          style="width: 12rem; height: 12rem"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReferralBuilding',
  components: {},

  data() {
    return {
      pageReady: false,

      renderTabs: [
        { title: 'tpd-lawyer', display_title: 'Tpd Referral' },
        {
          title: 'referral-information-uploads',
          display_title: 'Document Upload',
        },
        { title: 'referral-created', display_title: 'Referral Created' },
      ],
      // TOOD: This can probably reference off state.js
      medicalDocs: [
        {
          id: 1,
          api_title: 'gp_notes',
        },
        {
          id: 7,
          api_title: 'specialist_docs',
        },
        {
          id: 8,
          api_title: 'surgical_notes',
        },
        {
          id: 3,
          api_title: 'investigation_reports',
        },
        {
          id: 2,
          api_title: 'hospital_records',
        },
        {
          id: 5,
          api_title: 'medicolegal_docs',
        },
      ],
    }
  },

  computed: {
    isReferralCompleted() {
      if (this.$store.state.TPDReferral) {
        let data = this.$store.state.TPDReferral

        //check if this referral has any uploaded documents by lawyers
        let hasUploads =
          data.gp_notes.length === 0 &&
          data.hospital_records.length === 0 &&
          data.investigation_reports.length === 0 &&
          data.medicolegal_docs.length === 0 &&
          data.new_reports.length === 0 &&
          data.specialist_docs.length === 0 &&
          data.surgical_notes.length === 0
            ? false
            : true

        //however if its all marked unavailable, then referral uploads is completed
        let allMarked = this.medicalDocs.every((doc) =>
          data.unavailable_documents.includes(doc.id)
        )

        //check if each category is marked or uploaded
        let notMarked = this.medicalDocs.filter(
          (val) => !data.unavailable_documents.includes(val.id)
        )

        let isCompleted = notMarked.every(
          (doc) => data[doc.api_title].length > 0
        )

        //check if selected service requirement

        return data.service_requirement.service_type &&
          (allMarked || (isCompleted && hasUploads))
          ? true
          : false
      } else return false
    },
  },

  async mounted() {
    let payload = { id: this.$route.params.id }

    if (payload.id !== 'new') {
      await this.$store
        .dispatch('getTPDReferral', payload)
        .then((response) => {
          this.$store.commit('STORE_TPDREFERRAL', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    this.pageReady = true
  },

  methods: {
    isDisable(item) {
      if (
        item.title === 'tpd-lawyer' ||
        (item.title === 'referral-information-uploads' &&
          this.$route.params.id !== 'new')
      ) {
        return false
      } else if (!this.isReferralCompleted) {
        return true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-menu {
  z-index: 999;
}

.tpd-tabs {
  .nav-item {
    position: relative;

    .router-link-exact-active {
      .tpd-lawyer-nav {
        background-color: #32c3d6;
        color: white;
      }
    }

    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        border-top: 2px solid #32c3d6;
        background: none;
        width: 575%;
        max-width: 500px;
        z-index: -999;
        transform: translateY(-50%);
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        border-top: 2px solid #32c3d6;
        background: none;
        width: 575%;
        max-width: 500px;
        z-index: -999;
        transform: translateY(-50%);
      }
    }

    &:last-child {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        border-top: 2px solid #32c3d6;
        background: none;
        width: 800%;
        max-width: 700px;
        z-index: -999;
        transform: translateY(-50%);
      }

      .tpd-lawyer-nav {
        float: right;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 33%;
          left: -4%;
          width: 0;
          height: 0;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;

          border-left: 7px solid #32c3d6;
        }
      }
    }

    &:not(:first-child) {
      margin-left: 4rem;
    }

    .tpd-lawyer-nav {
      border: 1px solid #32c3d6;
      border-radius: 0;
      background-color: white;

      &:disabled {
        background-color: gray;
        border: none;
        opacity: 100;

        color: rgb(182, 182, 182);
      }
    }
  }
}
</style>
